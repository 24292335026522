// This file was generated by a script, do not edit it manually
// You're probably looking for spritesconfig.py
.bg-main {
    background-image: url('../images/sprites-main.png?h=07854016aee6cb55');
}
.ntw-icon-pds-formula-20 {
    .bg-main();
    height: 20px;
    width: 20px;
    background-position: -0px -0px;
}
.ntw-icon-globe-20 {
    .bg-main();
    height: 20px;
    width: 20px;
    background-position: -23px -0px;
}
.ntw-icon-myAlgo-16 {
    .bg-main();
    height: 16px;
    width: 16px;
    background-position: -0px -23px;
}
.ntw-icon-publicAlgo-16 {
    .bg-main();
    height: 16px;
    width: 16px;
    background-position: -19px -23px;
}
