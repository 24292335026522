#marketing-banner-container {
    margin-bottom: 65px;

    .marketing-banner {
        .banner-container {
            border: 1px solid #dadada;
            border-radius: 5px;
            padding: 10px 0;
            float: right;
            width: 410px;

            .banner-title {
                padding: 23px 3px 0px 10px;
                text-align: right;
                width: 130px;
                height: 80px;
                float: left;
            }

            .banner-message {
                border-left: 1px solid #dadada;
                float: right;
                width: 270px;
                padding: 0 15px;

                .general-text {
                    font-size: 12px;
                }
            }
        }
    }
}

.marketing-banner-ttd.marketing-banner {
    .banner-container {
        .banner-title {
            .ttd-title-start {
                font-size: 12px;
            }

            .ttd-title-name {
                color: #428bca;
                font-size: 20px;
            }
        }

        .banner-message {
            .ttd-download-link {
                display: inline-block;
                margin-top: 10px;
                min-width: 75px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .ttd-learn-more-link {
                float: right;
                margin-top: 18px;
                margin-right: 10px;
                font-size: 12px;
            }
        }

        .reg{
            font-size: 60%;
        }
    }
}
