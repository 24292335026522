@import "_mixins";

#user-workspaces {
    #workspaces_section {
        padding: 5px 15px 15px 15px;
        margin: 0;
        border: 1px solid #dddddd;
        .border-radius(6px);

        legend {
            width: inherit;
            border: none;
            padding: 0 10px;
            margin: 0;
        }

        .btn-group {
            margin-top: 10px;
        }
    }
}

#admin-templates {
    #templates_section {
        padding: 5px 15px 15px 15px;
        margin: 0;
        border: 1px solid #dddddd;
        .border-radius(6px);

        legend {
            width: inherit;
            border: none;
            padding: 0 10px;
            margin: 0;
        }

        .btn-group {
            margin-top: 10px;
        }
    }
}