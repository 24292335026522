/*
    in this file we "fix" the aspects of Bootstrap's CSS that de
    disagree with.
    Keeping all those tweaks in the same file will make it easier to
    address future Bootstrap upgrades and theme replacements.
    */

@import "_mixins";

// This fixes the broken icons for any element using glyphicons instead of font-awesome (e.g. the query builder plugin)
@icon-font-path: "../node_modules/bootstrap/fonts/";

.modal {
    form {
        margin: 0;
    }

    .modal-body {
        .lead {
            font-size: 16px; //21px was just too large
        }
    }

    &.market-data-dialog {
        .custom-button-width {
            width: 230px; //  !important;
            font-weight: bold;
        }
        .alert {
            margin-bottom: 0px; // !important;
        }
        .modal-header {
            color: #d23636;
        }
    }
}


//Remove ugly "not-allowed" mouse pointer on disabled fields.
.uneditable-input,
.uneditable-textarea,
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
    cursor: default;
}

// Change the font weight of these tags back to bold from 500 (Bootstrap3 override).
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}


// .dropdown-submenu is removed from bootstrap3. adding it back for our use.
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    .border-radius(0 6px 6px 6px);
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
    .border-radius(5px 5px 5px 0);
}

.dropdown-submenu > a:after {
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
    border-color: transparent;
    border-left-color: #000;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
}

.dropdown-submenu:hover > a:after {
    border-left-color: #666;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    .border-radius(6px 0 6px 6px);
}

// end of dropdown-submenu section.
