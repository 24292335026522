// hash: 07854016aee6cb55
// This file was generated by a script, do not edit it manually
// You're probably looking for spritesconfig.py
@import "_sprites-main";

.ntw-base-icon {
    display: inline-block;
    margin-top: 1px;
    vertical-align: text-top;
    background-repeat: no-repeat;
}

[class^="ntw-icon-"], [class*=" ntw-icon-"] {
    .ntw-base-icon();
}
