// Border Radius
.border-radius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
    -webkit-border-top-left-radius: @radius;
    -moz-border-radius-topleft: @radius;
    border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
    -webkit-border-top-right-radius: @radius;
    -moz-border-radius-topright: @radius;
    border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
    -webkit-border-bottom-right-radius: @radius;
    -moz-border-radius-bottomright: @radius;
    border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
    -webkit-border-bottom-left-radius: @radius;
    -moz-border-radius-bottomleft: @radius;
    border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
    .border-top-right-radius(@radius);
    .border-top-left-radius(@radius);
}
.border-right-radius(@radius) {
    .border-top-right-radius(@radius);
    .border-bottom-right-radius(@radius);
}
.border-bottom-radius(@radius) {
    .border-bottom-right-radius(@radius);
    .border-bottom-left-radius(@radius);
}
.border-left-radius(@radius) {
    .border-top-left-radius(@radius);
    .border-bottom-left-radius(@radius);
}



//    Adds a drop shadow effect.
//    usage:
//        .box-shadow(HorizDistance VertDistance DispersionSize rgba(red, green, blue, opacity))
//    example:
//        creating a vertical shadow, 5px below the element,
//        "blurred" by 7 pixels, in black ith 60% opacity.
//
//        .element {
//           .box-shadow(0 5px 7px rgba(0, 0, 0, 0.6))
//        }
.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

.box-shadow(@shadow1, @shadow2) {
    -webkit-box-shadow: @shadow1, @shadow2;
    -moz-box-shadow: @shadow1, @shadow2;
    box-shadow: @shadow1, @shadow2;
}

.box-sizing(@sizing) {
    -webkit-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    box-sizing: @sizing;
}

.blur(@blur-amount) {
    -webkit-filter: blur(@blur-amount);
    -moz-filter: blur(@blur-amount);
    -ms-filter: blur(@blur-amount);
    -o-filter: blur(@blur-amount);
    filter: blur(@blur-amount);
}

.display-flex() {
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
}

.display-inline-flex() {
    display: -moz-inline-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-inline-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-inline-flex;     /* NEW - Chrome */
    display: inline-flex;
}

.flex-grow(@value) {
    -webkit-webkit-flex-grow: @value;
    -moz-webkit-flex-grow: @value;
    -ms-flex-positive:@value;
    -ms-webkit-flex-grow: @value;
    -o-webkit-flex-grow: @value;
    -webkit-flex-grow: @value;
    flex-grow: @value;
}

.flex-shrink(@value) {
    -webkit-webkit-flex-shrink: @value;
    -moz-webkit-flex-shrink: @value;
    -ms-flex-negative:@value;
    -ms-webkit-flex-shrink: @value;
    -o-webkit-flex-shrink: @value;
    -webkit-flex-shrink: @value;
    flex-shrink: @value;
}

.justify-content(@value) {
    -ms-flex-pack: @value;
    -moz-box-pack: @value;
    -webkit-box-pack: @value;
    -webkit-justify-content: @value;
    justify-content: @value;
}

.unselectable() {
    //avoid text selection by mouse drag
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectable() {
    //avoid text selection by mouse drag
    -webkit-touch-callout: initial;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

// "Grabby hand" mouse pointer
.cursor-grab() {
    cursor: hand;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.cursor-grabbing() {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

//gradient background
@gradient-from-color: #d9d8d8;
@gradient-to-color: #e1e1e1;
@gradient-from-percent: 0%;
@gradient-to-percent: 100%;
.gradient(@from-color: @gradient-from-color, @to-color: @gradient-to-color,
                            @from-percent: @gradient-from-percent, @to-percent: @gradient-to-percent) {
    background-image: -moz-linear-gradient(@from-color @from-percent, @to-color @to-percent);
    background-image: -webkit-linear-gradient(@from-color @from-percent, @to-color @to-percent);
    background-image: linear-gradient(@from-color @from-percent, @to-color @to-percent);
    background-repeat: repeat-x;
}
.hgradient(@from-color: @gradient-from-color, @to-color: @gradient-to-color) {
    background-image: -moz-linear-gradient(right, @from-color, @to-color);
    background-image: -webkit-linear-gradient(left, @from-color, @to-color);
    background-image: linear-gradient(left, @from-color, @to-color);
    background-repeat: repeat-x;
}

//apply to any element to easily convert all colors to grayscale
.grayscale() {
    filter: grayscale(100%); // future support
    -webkit-filter: grayscale(100%); // For Webkit browsers
    filter: gray; //  For IE 6 - 9
    //For Firefox, sigh
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

.launch-button-cell() {
    border-left: 1px solid @grid-header-top-bordercolor;
    border-top: 1px solid @grid-header-top-bordercolor;
    border-right: 1px solid @tableheader-bottom-bordercolor;
    border-bottom: 1px solid @tableheader-bottom-bordercolor;
    &:active {
        border-left-color: @tableheader-bottom-bordercolor;
        border-top-color: @tableheader-bottom-bordercolor;
        border-right-color: @grid-header-top-bordercolor;
        border-bottom-color: @grid-header-top-bordercolor;
    }
}

// flash cell animation
.flash-background(@animationName, @backgroundColor, @flashColor ) {
    @-webkit-keyframes @animationName {
        0%, 100% {
            background-color: @backgroundColor;
        }
        50% {
            background-color: @flashColor;
        }
    }
    @keyframes @animationName {
        0%, 100% {
            background-color: @backgroundColor;
        }
        50% {
            background-color: @flashColor;
        }
    }
    -webkit-animation: @animationName 1s 0s 1 alternate;
    animation: @animationName 1s 0s 1 alternate;
}

// Given a @list of LESS variable names this method will generate a class for each
// variable and set the value of the variable on the @propertyName.
.generate-classes(@list, @propertyName) {
    .make-classes();
    .make-classes(@i: length(@list)) when (@i > 0) {
        .make-classes(@i - 1);
        @variableName: extract(@list, @i);
        .@{variableName} {
                @{propertyName}: @@variableName;
        }
    }
}

// for zooming on widgets
.widgetZoom(@scaleAmt) {
    transform: scale(calc(@scaleAmt));
    transform-origin: 0 0;
    width: (100%/@scaleAmt);
    height: (100%/@scaleAmt);
}
.loop(@counter) when (@counter < 101) {
    .loop((@counter + 1));

    &.zoom-level-@{counter} {
        .widgetZoom((@counter + 50) / 100);
    }
}
