.sysinfo {
    .tab-content {
        .table {
            border-top: none;
        }
    }
    .config {
        th {
            text-align: left;
        }
        .lbl {
            font-weight: bold;
        }
        .value {
            font-family: Consolas, monaco, "Courier New", monospace;
        }
    }

    .timezone-tip {
        margin-top: 10px;
        font-style: italic;
        text-align: right;
        color: #aaa;
    }

    #changelog {

        h3 {
            font-size: 17px;
            .version-date {
                font-size: 14px;
                font-weight: normal;
                color: #777;
            }
        }

        ul {
            border-bottom: solid 1px #ddd;
            padding-bottom: 5px;
        }
    }

    #icons {
        .icn-info {

            .icn {
                cursor: pointer;
            }

            .icn-class {
                vertical-align: bottom;
            }

            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
    }
}