// Animation-related utility functions. No real animation is defined here, just
//  the mixins that help creating them. For example, if we decide to create an
//  animation that highlights an element, it should be added to an _effects.less file, which
//  will probably include this mixins file.

.animation(@animname, @duration: 300ms) {
    animation: @animname @duration;
    -webkit-animation: @animname @duration;
    -moz-animation: @animname @duration;
    -o-animation: @animname @duration;
    -ms-animation: @animname @duration;
}

.transition(@a1) {
    transition: @a1;
    -webkit-transition: @a1;
    -moz-transition: @a1;
    -ms-transition: @a1;
    -o-transition: @a1;
}
.transition(@a1, @a2) {
    transition: @a1, @a2;
    -webkit-transition: @a1, @a2;
    -moz-transition: @a1, @a2;
    -o-transition: @a1, @a2;
}
.transition(@a1, @a2, @a3) {
    transition: @a1, @a2, @a3;
    -webkit-transition: @a1, @a2, @a3;
    -moz-transition: @a1, @a2, @a3;
    -o-transition: @a1, @a2, @a3;
}

.transition-property (@a1) {
    transition-property: @a1;
    -webkit-transition-property: @a1;
    -moz-transition-property: @a1;
    -o-transition-property: @a1;
}
.transition-property (@a1, @a2) {
    transition-property: @a1, @a2;
    -webkit-transition-property: @a1, @a2;
    -moz-transition-property: @a1, @a2;
    -o-transition-property: @a1, @a2;
}
.transition-property (@a1, @a2, @a3) {
    transition-property: @a1, @a2, @a3;
    -webkit-transition-property: @a1, @a2, @a3;
    -moz-transition-property: @a1, @a2, @a3;
    -o-transition-property: @a1, @a2, @a3;
}

.transition-duration (@a1) {
    transition-duration: @a1;
    -webkit-transition-duration: @a1;
    -moz-transition-duration: @a1;
    -o-transition-duration: @a1;
}
.transition-duration (@a1, @a2) {
    transition-duration: @a1, @a2;
    -webkit-transition-duration: @a1, @a2;
    -moz-transition-duration: @a1, @a2;
    -o-transition-duration: @a1, @a2;
}
.transition-duration (@a1, @a2, @a3) {
    transition-duration: @a1, @a2, @a3;
    -webkit-transition-duration: @a1, @a2, @a3;
    -moz-transition-duration: @a1, @a2, @a3;
    -o-transition-duration: @a1, @a2, @a3;
}

.transition-delay (@a1) {
    transition-delay: @a1;
    -webkit-transition-delay: @a1;
    -moz-transition-delay: @a1;
    -o-transition-delay: @a1;
}
.transition-delay (@a1, @a2) {
    transition-delay: @a1, @a2;
    -webkit-transition-delay: @a1, @a2;
    -moz-transition-delay: @a1, @a2;
    -o-transition-delay: @a1, @a2;
}
.transition-delay (@a1, @a2, @a3) {
    transition-delay: @a1, @a2, @a3;
    -webkit-transition-delay: @a1, @a2, @a3;
    -moz-transition-delay: @a1, @a2, @a3;
    -o-transition-delay: @a1, @a2, @a3;
}

.transition-timing-function (@a1) {
    transition-timing-function: @a1;
    -webkit-transition-timing-function: @a1;
    -moz-transition-timing-function: @a1;
    -o-transition-timing-function: @a1;
}
.transition-timing-function (@a1, @a2) {
    transition-timing-function: @a1, @a2;
    -webkit-transition-timing-function: @a1, @a2;
    -moz-transition-timing-function: @a1, @a2;
    -o-transition-timing-function: @a1, @a2;
}
.transition-timing-function (@a1, @a2, @a3) {
    transition-timing-function: @a1, @a2, @a3;
    -webkit-transition-timing-function: @a1, @a2, @a3;
    -moz-transition-timing-function: @a1, @a2, @a3;
    -o-transition-timing-function: @a1, @a2, @a3;
}

