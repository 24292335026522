// styles for the global error pages

.error-content {

    #exception-details {
        margin-top: 10px;
        legend {
            line-height: 20px;
            .error-note {
                font-style: italic;
                color: #bbbbbb;
                font-size: 12px;
            }
        }

        .stack-trace {
            font-family: Consolas, Menlo, Monaco, monospace;
            white-space: pre;
        }
    }

}